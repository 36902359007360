import React, { Fragment } from "react";
import { Container, Col, Row } from "react-bootstrap";
import style from "./Home.module.scss";
import gStyle from "../../SCSS/global.module.scss";
import Community from "../../Images/community-white.svg";
import Computer from "../../Images/computer-white.svg";
import F123 from "../../Images/f123-logo.svg";
//import Podium from "../../Images/podium_logos/cat_head_BW.svg";

const LandingPage = () => {
  return (
    <Fragment>
      <section className={style.hero}>
        <h1 className={`${gStyle.wh} ${gStyle.whTs} ${gStyle.ct}`}>
          Driven by Excellence
          <br />
          Programmed to Perform
        </h1>
      </section>
      <section className={`${style.s3} ${gStyle.trackImageBG}`}>
        <Container className={`${gStyle.container} ${gStyle.containerMd}`}>
          <Row className={`${style.col3} ${gStyle.ct}`}>
            <Col>
              <img
                src={Community}
                className={`${style.image} ${gStyle.cyanFilter} d-block mx-auto img-fluid w-50`}
                alt="Community"
              />
              <h4 className={`${gStyle.wh} ${gStyle.whTs}`}>
                Find a Community and start racing today
              </h4>
            </Col>
            <Col>
              <img
                src={F123}
                className={`${style.image} ${gStyle.cyanFilter} d-block mx-auto img-fluid w-60`}
                alt="F1 23"
              />
              <h4 className={`${gStyle.wh} ${gStyle.whTs}`}>
                Built for F1 23 League Managers and Drivers
              </h4>
            </Col>
            <Col>
              <img
                src={Computer}
                className={`${style.image} ${gStyle.cyanFilter} d-block mx-auto img-fluid w-50`}
                alt="Tools"
              />
              <h4 className={`${gStyle.wh} ${gStyle.whTs}`}>
                Advanced Broadcasting and Telemetry Tools
              </h4>
            </Col>
          </Row>
          {/** move to Champions Series / Details
          <Row className={gStyle.ct}>
            <Col>
              <h3 className={`${gStyle.wh} ${gStyle.whTs}`}>Races Hosted</h3>
              <p className={`${gStyle.xxlText} ${gStyle.wh}`}>30</p>
            </Col>
            <Col className={style.lrBorder}>
              <h3 className={`${gStyle.wh} ${gStyle.whTs}`}>Divisions</h3>
              <p className={`${gStyle.xxlText} ${gStyle.wh}`}>2</p>
            </Col>
            <Col>
              <h3 className={`${gStyle.wh} ${gStyle.whTs}`}>Drivers</h3>
              <p className={`${gStyle.xxlText} ${gStyle.wh}`}>20</p>
            </Col>
          </Row>
          */}
        </Container>
      </section>
      <section className={`s2 ${gStyle.darkGrayBG}`}>
        <Container className={`${gStyle.container} ${gStyle.containerMd}`}>
          <Row>
            <Col>
              <h2
                className={`${gStyle.lgText} ${gStyle.cyan} ${gStyle.cyanTs} mb-4`}
              >
                Who We Are
              </h2>
              <p className={`${gStyle.mdText} ${gStyle.wh}`}>
                Delta Zero Production (DZP) is a leading software development
                company in the sim racing industry, specializing in creating
                innovative tools and solutions that revolutionize the way
                racers, viewers, and league organizers experience and engage
                with sim racing. Established in 2022, DZP has quickly become a
                pioneer in the field, setting new standards for performance,
                user experience, and empowerment within the sim racing
                community.
              </p>
            </Col>
          </Row>
        </Container>
      </section>
      <section className={`${style.s5} ${gStyle.trackImageBG}`}>
        <Container className={`${gStyle.container} ${gStyle.containerMd}`}>
          <Row>
            <Col>
              <h2
                className={`${gStyle.lgText} ${gStyle.magenta} ${gStyle.magentaTs} mb-4`}
              >
                Core Values
              </h2>
            </Col>
          </Row>
          <Row className={style.row2}>
            <Col>
              <h3 className={`${gStyle.mdText} ${gStyle.wh} ${gStyle.whTs}`}>
                Innovation
              </h3>
              <p className={`${gStyle.wh} ${gStyle.noTs}`}>
                Pioneering groundbreaking software solutions in the sim racing
                industry
              </p>
            </Col>
            <Col>
              <h3 className={`${gStyle.mdText} ${gStyle.wh} ${gStyle.whTs}`}>
                Performance
              </h3>
              <p className={`${gStyle.wh} ${gStyle.noTs}`}>
                Helping users achieve their full potential on and off the track
              </p>
            </Col>
            <Col>
              <h3 className={`${gStyle.mdText} ${gStyle.wh} ${gStyle.whTs}`}>
                User-centricity
              </h3>
              <p className={`${gStyle.wh} ${gStyle.noTs}`}>
                Creating intuitive, easy-to-use tools tailored to the sim racing
                community
              </p>
            </Col>
          </Row>
        </Container>
      </section>
      <section className={`s4 ${gStyle.darkGrayBG}`}>
        <Container className={`${gStyle.container} ${gStyle.containerMd}`}>
          <Row>
            <Col>
              <h2
                className={`${gStyle.lgText} ${gStyle.cyan} ${gStyle.cyanTs} mb-4`}
              >
                Mission
              </h2>
              <p className={`${gStyle.mdText} ${gStyle.wh}`}>
                DZP's mission is to empower the sim racing community by
                providing cutting-edge software solutions, bridging the gap
                between virtual and real racing worlds, and enabling racers to
                achieve their full potential through advanced engineering tools,
                data analysis, and comprehensive league management systems.
              </p>
            </Col>
          </Row>
        </Container>
      </section>
      {/** Moved to champions/standings 
      <section className={`s6 ${gStyle.darkGrayBG}`}>
        <Container className={`${gStyle.container} ${gStyle.containerMd}`}>
          <Row>
            <Col>
              <h2
                className={`${gStyle.lgText} ${gStyle.cyan} ${gStyle.cyanTs} mb-4`}
              >
                Driver Standings
              </h2>
              <Standings />
            </Col>
          </Row>
        </Container>
      </section>
      */}
      {/* <Container className={`${gStyle.container} mt-3`}>
				<Row>
					<Col lg={7} md={9} xs={12} className={`${gStyle.mCenter} mt-5`}>
						<Card 
							className={`${gStyle.wh} ${gStyle.darkPurpleBG} mt-2 mb-3`}
							header='Champions Series'
							title='a F1 22 League by'
							subHeader='DELTA ZERO PRODUCTION'
						>
							<p className="card-text">
								Recruiting Drivers, Race Engineers, Race Stewards, Race
								Directors, Race Commentators, Content Creators, Moderators,
								Graphic Designers, 3D Artists, Web Developers, App Developers,
								and more
							</p>
							<Button
								variant="dark"
								type="button"
								href="https://forms.gle/5K5Cf9cxHvrdDgMe9"
								target="_blank"
								rel="noreferrer"
							>
								Click HERE to fill out our Interest Form
							</Button>
						</Card>
					</Col>
				</Row>
				<Row xs="auto" className="justify-content-center align-self-center mt-3">
					<Col lg={6} md={6} xs={12} className="mb-3">
						<Card
							className={`${gStyle.borderInfo} xs`}
							title= 'Commentary'
						>
							<p className="card-text">All races streamed on Twitch by a dedicated commentary team</p>
							<p className="card-text">Custom interactive graphics using telemetry data</p>
						</Card>
					</Col>
					<Col lg={6} md={6} xs={12} className="mb-3">
						<Card
							className={`${gStyle.borderWarning} xs`}
							title='Community'
						>
							<p className="card-text">Creating fun and engaging content</p>
							<p className="card-text">
								Providing opportunities fora diverse community to connect with
								one another
							</p>
						</Card>
					</Col>
				</Row>
			</Container> */}
    </Fragment>
  );
};

export default LandingPage;
